import Highcharts from "highcharts/es-modules/masters/highcharts.src.js";
import "highcharts/es-modules/masters/highcharts-more.src.js";
// Load the data module that can parse data from table, csv, Google Sheets,...
import "highcharts/es-modules/masters/modules/data.src.js";
import "highcharts/es-modules/masters/modules/exporting.src.js";
import "highcharts/es-modules/masters/modules/offline-exporting.src.js";
import "highcharts/es-modules/masters/modules/export-data.src.js";
import "highcharts/es-modules/masters/modules/accessibility.src.js";

const VALUE_REGEX = /\(?([$])?([-+\d.,]+)([%])?\)?/;
const SUPERSCRIPTS = "⁰¹²³⁴⁵⁶⁷⁸⁹";
const SUBSCRIPTS = "₀₁₂₃₄₅₆₇₈₉";

// Set global options as a side-effect.
const DEFAULT_OPTIONS = {
  chart: {
    styledMode: true,
    marginRight: 40,
    className: "highcharts-light"
  },
  title: {
    useHTML: true,
  },
  xAxis: {
    type: "category", // Uses the point names of the chart's series for categories.
    labels: {
      style: {
        textOverflow: "none", // So long labels are not truncated.
      },
    },
  },
  plotOptions: {
    // General options for all series
    series: {
      dataLabels: {
        enabled: true,
        // Ensures data labels are on top of, i.e., columns.
        crop: false,
        overflow: "none",
        allowOverlap: true,
        formatter: function() {
          // Use the custom `point.text` from parsing to format the data label.
          return this.point.text;
        },
      },
    },
    pie: {
      // Allow this series' points to be selected by clicking on the graphic
      // (columns, point markers, pie slices, map areas etc).
      // The selected points can be handled by point select and unselect events,
      // or collectively by the `getSelectedPoints` function.
      allowPointSelect: true,
      cursor: "pointer",
      // Since 2.1, pies are not shown in the legend by default. However,
      // we set the data text as labels, so we need to show the series type
      // in legend.
      showInLegend: true,
    },
    waterfall: {
      dataLabels: {
        inside: false,
      },
    }
  },
  tooltip: {
    // Use the custom `point.text` from parsing to render the point's tooltip.
    pointFormat: `<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.text}</b><br/>`,
  },
  lang: {
    thousandsSep: ",",
  },
  credits: {
    enabled: false,
  }
}

Highcharts.setOptions(DEFAULT_OPTIONS);

/** A chart representing tabular data. */
class Chart {
  /**
   * Creates a chart from a HTMLTableELement with tabular data.
   *
   * @param {HTMLTableElement} table - The table containing tabular data.
   * @param {Highcharts.Options} [options] - Extra options.
   * @param {Highcharts.ChartCallbackFunction} [callback]
   *
   * The <caption> inside the <table>, if exists, becomes the chart's title.
   *
   * This class is different in which the container to insert the chart into
   * must be speficied in `options.chart.renderTo` if the chart needs to be
   * displayed in the DOM.
   */
  constructor(
    table,
    {
      data = {},
      exporting = {},
      ...options
    } = {},
    callback,
  ) {
    const titleText = table.caption?.innerHTML;
    /** @type string[][] */
    const series = [];

    const type = options.chart?.type || "column";

    return new Highcharts.Chart({
      title: {
        // Set title's text from table's <caption>.
        text: titleText,
      },

      // Default to use two y-axis.
      yAxis: [
        {
          title: null,
          labels: {
            formatter: function() {
              const axis = this.axis;

              // Let Highchart formats the label first.
              let label = axis.defaultLabelFormatter.call(this);

              // We don't want to handle the 0 tick.
              if (`${ label }` === "0") {
                return label;
              }

              // Because we don't have a way to determine the format of the data,
              // we have to grab the first data point in the first series. Other data
              // should have the same format.
              // These data have a custom `text` property from parsing the table.
              const firstDataText = axis.series[0].userOptions.data[0].text || "";
              const prefix = firstDataText[0];
              const suffix = firstDataText[firstDataText.length - 1];

              if (prefix === "$") {
                return `${ prefix }${ label }`;
              }

              if ("%x".indexOf(suffix) > -1) {
                return `${ label }${ suffix }`;
              }

              // Handle thousand-comma..
              if (/^[0-9]{4}$/.test(label)) {
                return Highcharts.numberFormat(label, 0);
              }

              return label;
            }
          },
        },
        {
          opposite: true,
          title: {
            text: null,
          },
        },
      ],

      exporting: {
        // Also needs to set it here so HTML can be rendered on display.
        tableCaption: titleText,
        ...exporting,
      },
      data: {
        table,
        // Callback after the data are parsed but not yet used to render the chart.
        // We need to strip out any prefix and/or suffix so Highchart can take the numeric value.
        parsed(/** @type {Highcharts.DataValueType[][]} **/parsedColumns) {
          const domParser = new DOMParser();

          parsedColumns.forEach((column, columnIndex) => {
            // In here, the first column contains the points' name.
            if (columnIndex === 0) {
              return;
            }

            // Collecting our own data for mapping to points later.
            const rawData = [];
            series.push(rawData);

            column.forEach((cell, index) => {
              // First cell is the name of the point, not data.
              if (index > 0) {
                // Because JS ignore zeros after decimal points, the `cell` numeric value can
                // be different than its text. Therefore, we query the actual cell element and
                // retrieve its `textContent` from there.
                const $cell = table.querySelectorAll("tr")[index]?.querySelectorAll("td")[columnIndex];
                rawData.push($cell?.textContent || cell);
              }

              // Handles superscripts and subscripts.
              cell = `${cell}`.replace(/<sup>([\d]+)?<\/sup>/g, (match, numbers) => {
                return Array.from(numbers).map(n => SUPERSCRIPTS[n]).join("");
              }).replace(/<sub>([\d]+)?<\/sub>/g, (match, numbers) => {
                return Array.from(numbers).map(n => SUBSCRIPTS[n]).join("");
              });

              /** Strips out HTML */
              cell = domParser.parseFromString(cell, "text/html").documentElement.textContent;

              // Check for possible prefix and/or suffix.
              const [, valuePrefix = "", numeric, valueSuffix = ""] = `${ cell }`.match(VALUE_REGEX) || [];
              if (numeric) {
                column[index] = parseFloat(numeric.replace(/,/g, ""));
              } else {
                column[index] = cell;
              }
            });
          });

          return undefined;
        },
        // Callback that is evaluated when the data is finished loading and parsed.
        // We use this to convert series data to points with custom text value.
        complete(chartOptions) {
          const xAxes = [].concat.apply(options.xAxis || []);

          // Because we stripped out prefix and/or suffix from parsed data,
          // we need to retrieve back the actual text content to associate with
          // each points in the series. This custom `text` property will be used
          // in data labels and tooltip.
          chartOptions.series.forEach(function(seriesOptions, index) {
            // Checks if this xAxis has a mirror axis linked to it.
            const mirrorAxis = xAxes.find(axis => axis.opposite && axis.linkedTo === index);

            const seriesData = series[index];
            const points = [];
            seriesOptions.data.forEach(function([ name, value ], pointIndex) {
              const point = {
                name,
                // If there is a mirror, negate the value so both series are stacked
                // in opposite ways. Mostly for Female/Male chart.
                y: value * (mirrorAxis ? -1 : 1),
                text: `${ seriesData[pointIndex] }`,
              };

              if (type === "waterfall") {
                // Waterfall chart uses the last point as the sum.
                if (pointIndex === seriesData.length - 1) {
                  point.isSum = true;
                }
              }

              points.push(point);
            });

            seriesOptions.data = points;
          });

        },
        ...data,
      },
      ...options,
    }, (chart) => {
      // Use small delay for the modules to fully register.
      setTimeout(function() {
        // Shows the data in a table below the chart.
        // Requires Export-data module.
        chart.viewData();
        // Then hides it immediately so the user does not see it,
        // but the `<table>` remains in the DOM for the FDO to use.
        // Requires Export-data module.
        chart.hideData();

        const numberCells = chart.dataTableDiv.querySelectorAll("td.number");
        const seriesCount = series.length;

        numberCells.forEach(function(cell, index) {
          // Figure out corresponding indices with some math.
          const seriesIndex = index % seriesCount;
          const pointIndex = Math.floor(index / seriesCount);
          const text = series[seriesIndex][pointIndex];
          // Set the cell's text to the series' text.
          cell.textContent = text;
        });
      }, 0);

      callback && callback(chart);
    });
  }
}

// Renders all the charts on the page.
document.querySelectorAll("[is$=-chart]").forEach(container => {
  const type = container.getAttribute("is")?.replace("-chart", "");
  if (!type) {
    return;
  }

  const table = container.querySelector("table");
  const caption = container.querySelector("[slot=\"caption\"]");

  const stacking = container.getAttribute("data-stacking");

  if (table) {
    const options = {
      chart: {
        renderTo: container,
        type: type === "donut" ? "pie" : type,
      },
      caption: {
        text: caption?.innerHTML,
      },
      data: {
        switchRowsAndColumns: false,
      },
      plotOptions: {
        pie: {
          innerSize: type === "donut" ? "80%" : "0",
        },
        series: {
          stacking,
        },
      },
    }

    if (stacking === "negative") {
      options.xAxis = [
        {
          reversed: false,
        },
        {
          reversed: false,
          opposite: true, // mirror axis on right side
          linkedTo: 0, // links to the first axis.
          labels: {
            enabled: false,
          },
        },
      ];
    }

    new Chart(table, options);
  }
});

export { Chart };
